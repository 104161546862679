<template>
    <div>
        <v-dialog v-model="dialog" max-width="1000" max-height="90vh">
            <app-files-viewer
                v-model="valueLocal"
                :filter="filter"
                :is-readonly="isReadonly"
                :is-disabled="isDisabled"
                :file-table-name="fileTableName"
                @close="dialog = false" />
        </v-dialog>
        <app-autocomplete-multiple
            :key="autocompleteKey"
            v-model="valueLocal"
            :schema="getSchema('Files')"
            label="Files"
            :is-readonly="isReadonly"
            :is-disabled="isDisabled"
            claim-name="Files"
            @input="$emit('change')" />
        <v-btn
            class="pr-2 pl-2"
            min-width="0"
            :disabled="isDisabled"
            @click="saveAndOpenDialog()">
            <v-icon class="mr-1">
                mdi-folder-multiple-image
            </v-icon>
            Save and View Files
        </v-btn>
    </div>
</template>

<script>
export default {

    components: {
        appFilesViewer: () => import('@/components/AppFilesViewer'),
        appAutocompleteMultiple: () => import('@/components/AppAutocompleteMultiple')
    },
    props: {
        value: {
            type: Array,
            default: null
        },
        itemId: {
            type: String,
            default: null
        },
        parentColumnName: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        isReadonly: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        canOpen: {
            type: Boolean,
            default: false
        },
        fileTableName: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            dialog: false,
            autocompleteKey: 0,
            viewFilesClicked: false
        };
    },

    computed: {
        valueLocal: {
            get() {
                return this.value;
            },
            set(value) {
                this.autocompleteKey += 1;
                this.$emit('input', value);
            }
        },
        filter() {
            let filter = {};
            filter[this.parentColumnName] = this.itemId;
            return filter;
        }
    },

    watch: {
        canOpen() {
            if (this.canOpen && this.viewFilesClicked) {
                this.viewFilesClicked = false;
                this.dialog = true;
            }
        }
    },

    methods: {
        saveAndOpenDialog() {
            this.$emit('save', true);
            this.viewFilesClicked = true;
        }
    }
};
</script>
